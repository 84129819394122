@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&display=swap');

#token-migration {
  background-color: #fff;
}

.background-picture-token-migration {
  background-image: url('../images/banner_img.svg');
  background-position-x: right;
  background-repeat: no-repeat;
  background-position-y: top;
  background-size: 49%;
  flex: 1;
}

@media (max-width: 450px) {
  .background-picture-token-migration {
    background-size: 93% !important;
  }

  .token-migration-hero .hero-content {
    padding: 390px 30px 0px 30px !important;
  }
}

@media (max-width: 600px) and (min-width: 450px) {
  /* #token-migration .dropdown {
    display: none;
  } */
  .background-picture-token-migration {
    /* background-position-x: right; */
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: 80%;
    flex: 1;
  }

  .token-migration-hero .hero-content {
    padding: 390px 30px 0px 30px !important;
  }
}

@media (max-width: 1075px) and (min-width: 600px) {
  .background-picture-token-migration {
    background-size: 60% !important;
  }
}

.token-migration-hero {
  width: 100vw;
  display: flex;
  background-color: white;
}

.token-migration-hero .hero-content {
  display: flex;
  padding: 190px 30px 0px 30px;
  align-items: center;
  gap: 55px;
}

.token-migration-hero .hero-headings {
  display: flex;
  width: 600px;
  flex-direction: column;
  gap: 35px;
}

.token-migration-hero .hero-headings > h1 {
  font-size: 100px;
  font-family: 'Playfair Display', serif;
  font-weight: 400;
}

.token-migration-hero .hero-headings > p {
  opacity: 0.6;
  font-size: 14px;
}

.vertical-text {
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 8px;
  opacity: 0.7;
  text-transform: uppercase;
}

.convertion-calculator {
  padding: 30px 0;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 20px;
}

.convertion-calculator > button {
  padding: 10px 20px;
  border: 0;
  outline: 0;
  min-width: 200px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #17e7d6;
  color: #112455;
}

.convertion-calculator-field {
  display: flex;

  flex-direction: column;
  gap: 10px;
}

.coin-name-chip {
  display: flex;
  gap: 15px;
  align-items: center;
}

.coin-name-chip p {
  opacity: 0.6;
  font-size: 13px;
}

.convertion-calculator-field p {
  opacity: 0.6;
  font-size: 13px;
}

.coin-name-chip img {
  width: 30px;
  height: 30px;
}

.convertion-calculator-field > div {
  display: flex;
  gap: 15px;
  align-items: center;
}

.max-div {
  display: unset !important;
  position: relative;
}

.max-div .max_btn {
  position: absolute;
  right: 2%;
  padding: 8px 10px;
  outline: none;
  background-color: transparent;
  border-radius: 5px;
  cursor: pointer;
  color: #112455;
  height: 30px;
  width: 65px;
  border: 1px solid #0935a426;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
}

.convertion-calculator-field-text {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.404);
  padding: 10px;
  width: 270px;
  display: flex;
  justify-content: space-between;
  height: 30px;
}

.convertion-calculator-field-text > input {
  outline: 0;
  border: 0;
  flex: 1;
  max-width: 211px;
  background-color: transparent;
  height: 100%;
  font-size: 21px;
}

/* .convertion-calculator-field-text>button {
  padding: 8px 10px;
  outline: none;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #112455;
  height: 30px;
  
} */

.convertion-calculator .arrow-icon {
  padding: 0 20px;
}

@media (max-width: 500px) {
  .header {
    padding: 20px 10px;
  }

  .gird_mobile_layout {
    width: 70%;
  }

  .dropdown {
    width: 50%;
    padding-left: 20px !important;
  }

  .dropdown .drop_inner {
    margin-left: 1rem;
  }

  .dropdown .dropmenu {
    width: 80px;
  }

  .drop_down_btn {
    padding-left: 7px !important;
  }

  .drop_down_btn .btn-round {
    width: 130px;
  }

  .drop_down_btn .text-btn {
    font-size: 12px;
  }
}

@media (max-width: 1075px) {
  .token-migration-hero .hero-headings {
    width: min-content;
  }

  .token-migration-hero .hero-headings > h1 {
    font-size: 50px;
  }

  .convertion-calculator .arrow-icon {
    transform: rotate(90deg);
  }

  .convertion-calculator {
    flex-direction: column;
  }
}

.text-section > h2 {
  font-size: 32;
  font-family: 'Playfair Display', serif;
}

.text-section > a {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.text-section {
  display: flex;
  flex-direction: column;
  padding: 100px;
  gap: 40px;
  line-height: 30px;
}

.token-migration-footer > button {
  outline: 0;
  border: 1px solid black;
  background-color: transparent;
  color: black;
  border-radius: 20px;
  cursor: pointer;
  width: 150px;
  height: 30px;
}

.token-migration-footer {
  display: flex;
  justify-content: space-between;
  padding: 30px 40px 80px 40px;
  flex-wrap: wrap;
  gap: 30px;
  text-align: center;
  align-items: center;
}

@media (max-width: 500px) {
  .convertion-calculator {
    width: 100%;
    align-self: center;
  }

  .token-migration-hero .hero-headings {
    width: 100%;
  }

  .convertion-calculator-field {
    width: 100%;
  }

  .convertion-calculator-field-text {
    width: calc(100% - 30px);
  }
}

@media (max-width: 888px) {
  .token-migration-hero .hero-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }

  .vertical-text {
    transform: rotate(0deg);
    writing-mode: unset;
    font-size: 13px;
    width: 150px;
    font-weight: normal;
    letter-spacing: 4px;
    opacity: 0.7;
    text-transform: uppercase;
  }

  .token-migration-footer {
    flex-direction: column;
  }

  .text-section {
    padding: 100px 30px;
  }
}

.token-migration-model-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.3);
}

.token-migration-model .closeBtn {
  outline: 0;
  border: 0;
  background-color: transparent;
}

.token-migration-model {
  background-color: white;
  max-width: 450px;

  padding: 40px;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: fit-content;
  min-height: 300px;
  border-radius: 20px;
}

.primary-button-token-migration {
  padding: 10px 20px;
  border: 0;
  outline: 0;
  min-width: 200px;
  width: 100%;
  height: 50px;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 600;
  background-color: #17e7d6;
  color: #112455;
}

.token-migration-loader {
  border: 8px solid #17e7d6;
  /* Green color */
  border-top: 8px solid transparent;
  margin-bottom: 20px;
  border-left: 8px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.drop_cronos {
  background: #000 !important;
  border-radius: 50px !important;
}

.migrate_level_dollar {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #9aa6cf;
}

.cross_chain {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #17e7d6;
  background: linear-gradient(0deg, rgba(23, 231, 214, 0.1), rgba(23, 231, 214, 0.1));
}

.cross_chain h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  color: #112455;
  padding-bottom: 0.7rem;
}

.cross_chain p {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 700;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
}

.migrate_cross {
  padding: 16px;
  border-radius: 12px;
  border: 1px solid #17e7d6;
  background: linear-gradient(0deg, rgba(23, 231, 214, 0.1), rgba(23, 231, 214, 0.1));
}

.migrate_cross h3 {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  color: #112455;
  padding-bottom: 0.5rem;
}

.migrate_cross p {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #112455;
}
